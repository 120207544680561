export const InputEmail = ({ setEmail }: { setEmail: (email: string) => void }) => {
  return (
    <div className="mt-4 flex w-full flex-col whitespace-nowrap">
      <div className="text-xs font-semibold leading-none tracking-wide text-glyphs-shade">
        メールアドレス
      </div>
      <input
        required
        className="focus:border-1 border-1 mt-2 min-h-[40px] w-full flex-1 shrink gap-2 self-stretch rounded border border-border bg-white px-4 py-2.5 text-base leading-snug tracking-wider text-glyphs-normal focus:border-active"
        name="email"
        placeholder="example@example.com"
        type="email"
        onChange={(e) => setEmail(e.target.value)}
      />
    </div>
  );
};
